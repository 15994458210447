import React from 'react'
import './CalculatorResults.scss';
import {EstimateResponseDto} from "../../../api/EstimateApi";
import {Button, Typography} from "@mui/material";
import {calculatePercentage, formatString} from "../../../utils/NumberUtils";
import {chooseRandom} from "../../../utils/ArrayUtils";

interface CalculatorResultsProps {
    estimate: EstimateResponseDto
    onFormReset: () => void
}

export const CalculatorResults: React.FC<CalculatorResultsProps> = ({ estimate, onFormReset }) => {

    const thingsToBuy = [
        { value: 364900, min: 5, text: "average homes in Chicago" },
        { value: 149, min: 100, text: "club box tickets to see the Chicago Cubs" },
        { value: 495, min: 50, text: "kitchen table seats at a 3 Michelin star restaurant"},
        { value: 18.99, min: 10, text: "classic Chicago deep dish pizzas"},
        { value: 23_000_000, min: 2, text: "exact replicas of the Chicago Bean"}
    ]

    const generateFlavourText = (savings: number): string => {
        let options = thingsToBuy.map(x => {
            return {
                ...x,
                canBuy: Math.floor(savings/x.value)
            }
        }).filter(x => x.canBuy >= x.min && x.canBuy < 10000)
        let chosen = chooseRandom(options)
        return formatString(chosen.canBuy) + " " + chosen.text
    }

    return (
        <div className="CalculatorResults">
            <img className="logo" src="/images/zerigo.png" alt="Zerigo logo"/>
            <Typography variant="h2">Your results</Typography>
            <Typography variant="h4">In the first year you could save</Typography>
            <Typography className="brand-gradient" variant="h1">${formatString(estimate.firstYearSavings)}</Typography>
            <Typography variant="h4">and over the next five years</Typography>
            <Typography className="brand-gradient" variant="h1">${formatString(estimate.fiveYearSavings)}</Typography>
            <Typography variant="h4">that’s enough to buy {generateFlavourText(estimate.fiveYearSavings)}!</Typography>
            <Typography variant="h3">
                Your guess of <b>${formatString(estimate.estimate)}</b> was <b>{formatString(calculatePercentage(estimate.estimate, estimate.fiveYearSavings), 2)}%</b> {estimate.estimate > estimate.fiveYearSavings? "higher" : "lower"}
            </Typography>
            <Button variant="contained" onClick={onFormReset}>
                Back to start
            </Button>
        </div>
    )

}