import axios, {AxiosResponse} from "axios";

export interface MakeEstimateDto {
    name: string
    company: string
    email: string
    phone?: string
    enrolledEmployees: number
    estimate: number
}

export interface EstimateResponseDto {
    name: string
    company: string
    employees: number
    estimate: number
    firstYearSavings: number
    fiveYearSavings: number
    percentageOff: number
}

export interface LeaderboardDataResponse {
    lastUpdated: string
    leaderboard: EstimateResponseDto[]
    totalSavings: number
    charityDonations: number
}

export const getData = (r: AxiosResponse) => r.data

export const makeEstimate = (dto: MakeEstimateDto) =>
    axios.put(`/api/estimate`, dto).then(getData)

export const getLeaderboardData = () =>
    axios.get("api/leaderboard").then(getData)

