import ReactDOM from 'react-dom';
import App from './components/App';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';

const fontDefaults = {
  fontWeight: 400,
  fontStyle: "normal"
}

const theme = createTheme({
  typography: {
    h1:{ fontSize: "64px", ...fontDefaults },
    h2:{ fontSize: "48px", ...fontDefaults },
    h3:{ fontSize: "40px", ...fontDefaults },
    h4:{ fontSize: "32px", ...fontDefaults },
    fontFamily: [
      "Lexend",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif"
    ].join(",")
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: "320px",
          borderRadius: "50px",
          background: "#343433",
          "&:hover": { background: "#111111" },
          padding: "16px",
          fontSize: "20px"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "white"
        }
      }
    }
  }
});


ReactDOM.render(
  <ThemeProvider theme={theme}>
  <Router>
    <App />
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
