import React from 'react'
import {useFormikContext} from "formik";
import {TextField, TextFieldProps} from "@mui/material";

interface FormikTextFieldProps<T> {
    formikField: Extract<keyof T, string>
}

export const FormikTextField: React.FC<FormikTextFieldProps<any> & TextFieldProps> = <T,>({
    formikField,
    ...props
}: FormikTextFieldProps<T> & TextFieldProps) => {
    const formik = useFormikContext<T>();


    return (
        <TextField id={formikField.toString()}
                    name={formikField.toString()}
                    value={formik.values[formikField] ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        (formik.touched[formikField] || formik.status === "submitClicked")
                            && Boolean(formik.errors[formikField])
                    }
                    helperText={
                        (formik.touched[formikField] || formik.status === "submitClicked")
                            && formik.errors[formikField]
                    }
                   {...props}
        />
    )

}