import React, {useState} from 'react'
import './CalculatorForm.scss';
import {InputAdornment, Typography} from "@mui/material";
import {FormikTextField} from "../../common/Input/FormikTextField/FormikTextField";
import {LoadingButton} from "@mui/lab";
import {Formik, FormikHelpers} from "formik";
import {useMutation} from "react-query";
import {EstimateResponseDto, makeEstimate} from "../../../api/EstimateApi";
import * as Yup from "yup";
import {AxiosError} from "axios";

interface CalculatorFormProps {
    estimateCallback: (estimate: EstimateResponseDto) => void
}

interface CalculatorFormValues {
    name?: string
    company?: string
    email?: string
    phone?: string
    enrolledEmployees?: number
    estimate?: number
}
export const CalculatorForm: React.FC<CalculatorFormProps> = ({ estimateCallback }) => {

    const [error, setError] = useState<string>()

    const doEstimate = useMutation({
        mutationFn: makeEstimate,
        onSuccess: (data, variables, context) => {
            estimateCallback(data)
        },
        onError: (error: AxiosError) => {
            setError(error?.response?.data?.title ||
                "Whoops! Something went wrong. Please try again.")
        }
    })

    const initialValues: CalculatorFormValues = { }

    // for ease of reading the validation spec
    const estimateDtoSchema = Yup.object().shape({
        name: Yup.string()
            .max(50, "That looks a bit long, try just using your first name.")
            .required('Please provide a name'),
        company: Yup.string()
            .required('Please provide a company name'),
        email: Yup.string()
            .email("Whoops! That email address doesn't look right")
            .required('Please provide an email address'),
        phone: Yup.string()
            .min(5, "That looks a bit short for a phone number")
            .max(20, "That looks a bit long for a phone number"),
        enrolledEmployees: Yup.number()
            .min(1, "Need to have at least one enrolled employee")
            .max(100_000_000, "That's a few too many employees there!")
            .required("How many enrolled employees do you have?"),
        estimate: Yup.number()
            .min(1, "You can definitely save more than that!")
            .max(500_000_000_000, "Even we can't save you that much!")
            .required("Please provide an estimate")

    });

    const submit = (values: CalculatorFormValues, formik: FormikHelpers<CalculatorFormValues>) => {
        formik.setSubmitting(true)
        formik.setStatus("submitClicked")
        doEstimate.mutate({
            name: values.name!,
            company: values.company!,
            email: values.email!,
            phone: values.phone,
            enrolledEmployees: values.enrolledEmployees!,
            estimate: values.estimate!,
        },{
            onSettled: () => {
                formik.setSubmitting(false)
            }}
        )
    }

    return (
        <div className="CalculatorForm">
            <Formik
                initialValues={initialValues}
                validationSchema={estimateDtoSchema}
                onSubmit={submit}>
                {formik => (

                    <form className="form" onSubmit={
                        (e) => {
                            formik.setStatus("submitClicked");
                            formik.handleSubmit(e);
                        }}>
                        <img className="logo" src="/images/zerigo.png" alt="Zerigo logo"/>
                        <Typography className="title" variant="h3">Guess how much Zerigo Health could save you!</Typography>
                        <Typography className="subtitle" variant="h5">
                            More than <span className="brand-highlight">$20 billion*</span> is spent
                            treating and prescribing medications for psoriasis and eczema every year.
                        </Typography>
                        <Typography className="subtitle" variant="h5">
                            <span className="brand-highlight">Zerigo Health</span> could help you reduce these costs.
                        </Typography>


                        <div className="info">
                            <FormikTextField label="Name" formikField="name" />
                            <FormikTextField label="Company" formikField="company" />
                            <FormikTextField label="Email Address" formikField="email" />
                            <FormikTextField label="Phone Number - Optional" formikField="phone" />
                            <FormikTextField label="Number of enrolled employees" type="number" formikField="enrolledEmployees" />
                        </div>
                        <div className="estimate">
                            <Typography variant="h5" className="estimate-label">Guess how much you could potentially save in 5 years with Zerigo Health</Typography>
                            <FormikTextField
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                                label="Estimate"
                                type="number"
                                formikField="estimate" />
                        </div>
                        <LoadingButton loading={formik.isSubmitting} variant="contained" type="submit">
                            Make your guess
                        </LoadingButton>
                        { error? <Typography variant="subtitle1" className="error">{error}</Typography> : ""}
                    </form>
                )}
            </Formik>

            <div className="footer">
                <Typography variant="body2"><b>$20 billion:</b> Psoriasis drug market, 2021-2016, Mordor Intelligence</Typography>
                <Typography variant="body2"><b>Terms of use:</b> The Zerigo Health System is an Ultraviolet Light Emitting Medical Device intended for use in localized phototherapeutic treatment of dermatologic conditions such as psoriasis, atopic dermatitis (eczema), seborrheic dermatitis, and leukoderma on all skin types (I-VI). Available only upon a physician's prescription. </Typography>
            </div>
        </div>
    )

}