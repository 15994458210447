import React from 'react'
import './Leaderboard.scss';
import {Typography, Skeleton} from "@mui/material";
import {useQuery} from "react-query";
import {getLeaderboardData, LeaderboardDataResponse} from "../../../api/EstimateApi";
import {formatString} from "../../../utils/NumberUtils";

interface LeaderboardProps {

}

export const Leaderboard: React.FC<LeaderboardProps> = () => {

    const { isLoading, isError, data, error } = useQuery<LeaderboardDataResponse>(
        "leaderboard",
        getLeaderboardData,
        { refetchInterval: 1000 }
    )

    const formatName = (name: string) => {
        let names = name.split(" ")
        let formattedName = names[0];
        if(names.length > 1)
            formattedName += " " + names.pop()!.substring(0,1).toUpperCase()
        return formattedName
    }

    return (
        <div className="Leaderboard">
            <div className="header flex-row">
                <img className="logo" src="/images/zerigo.png" alt="Zerigo logo"/>
                <div className="text flex-column">
                    <Typography variant="h1" className="title fugaz">Savings Leaderboard</Typography>
                    <Typography variant="h4">at 25th Annual Health & Productivity Forum</Typography>
                </div>
            </div>
            { isLoading? <div className="loading flex-column">
                    <Skeleton variant="rectangular" width={500} height={100} />
                    <Skeleton variant="rectangular" width={500} height={100} />
                    <Skeleton variant="rectangular" width={500} height={100} />
                </div> :
                isError? error :
                <div className="content flex-row">
                    <div className="leaderboard flex-column">
                        { data && data.leaderboard.length > 0 ? data?.leaderboard.map((estimate, index) =>
                                <div className={`banner-wrapper flex-row ${index%2===0?"even":"odd"}`} key={estimate.company}>
                                    <div className="banner flex-row">
                                        <Typography variant="h1"  className="position fugaz">
                                            { index === 0? "1ST" :
                                                index === 1? "2ND" :
                                                index === 2? "3RD" :
                                                ((index+1) + "TH")
                                            }
                                        </Typography>
                                        <div className="details flex-column">
                                            <Typography variant="h2" className="fugaz">{formatName(estimate.name)}</Typography>
                                            <Typography variant="h5" className="fugaz">
                                                {`${formatString(estimate.percentageOff, 2)}% off (saving $${formatString(estimate.fiveYearSavings)})`}
                                            </Typography>
                                        </div>
                                    </div>
                                    <svg viewBox="0 0 100 100">
                                        <polygon className="triangle" points="0 0, 0 100, 100 100"/>
                                    </svg>
                                </div>
                            ) :
                            <div className={"no-results flex-column"}>
                                <Typography variant="h2">
                                    More than <span className="brand-highlight">$20 billion</span> is spent
                                    treating and prescribing medications for psoriasis and eczema every year.
                                </Typography>
                                <Typography variant="h2">
                                    Find out how much <span className="brand-highlight">Zerigo Health</span> could
                                    save you.
                                </Typography>
                            </div>
                        }
                    </div>
                    <div className="overview flex-column">
                        <Typography variant="h2" className="fugaz">
                            <span className="brand-highlight">${formatString(data!.totalSavings)}</span> in potential savings, and counting!
                        </Typography>
                        <Typography variant="h2" className="fugaz">
                            <span className="brand-highlight">${formatString(data!.charityDonations)}</span> donated to charity
                        </Typography>
                    </div>
                </div>
            }
        </div>
    )

}