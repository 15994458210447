import React, {useState} from 'react'
import {EstimateResponseDto} from "../../../api/EstimateApi";
import './CalculatorPage.scss';
import {CalculatorResults} from "../CalculatorResults/CalculatorResults";
import {CalculatorForm} from "../CalculatorForm/CalculatorForm";



interface CalculatorPageProps {

}



export const CalculatorPage: React.FC<CalculatorPageProps> = () => {

    const [ estimate, setEstimate ] = useState<EstimateResponseDto | null>()

    return (
        <div className="Calculator">
            { estimate?
                <CalculatorResults estimate={estimate} onFormReset={() => setEstimate(null)} /> :
                <CalculatorForm estimateCallback={setEstimate}/>}
        </div>
    )

}
