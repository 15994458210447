import {CalculatorPage, Leaderboard} from 'components/pages';
import './App.scss';
import {QueryClient, QueryClientProvider} from "react-query";
import {Route, Routes } from 'react-router-dom';

function App() {

    const queryClient = new QueryClient()

    return (
        <QueryClientProvider client={queryClient}>
            <div className="App">

                <Routes>
                    <Route path="/" element={<CalculatorPage />} />
                    <Route path="/leaderboard" element={<Leaderboard />} />
                    <Route path="*" element={<div>Not found</div>} />
                </Routes>

            </div>
        </QueryClientProvider>
  );
}

export default App;

