const numberFormat = new Intl.NumberFormat("en-US")

export const formatString = (value: number, places: number = 0) =>
    numberFormat.format(Number(value.toFixed(places)))

export const calculatePercentage = (estimate: number, actual: number) =>
    (Math.abs(estimate - actual) / actual) * 100

export const random = (from: number, to: number) =>
    from + Math.floor(Math.random() * (to-from));

export const repeat = (times: number) =>
    Array.from(Array(times).keys())