import {random} from "./NumberUtils";

export const distinct = (stringArr: string[]) => {
    var seen: any = {};
    return stringArr.filter(item =>
        seen.hasOwnProperty(item) ? false : (seen[item] = true)
    );
}

export const chooseRandom = <T>(array: T[]): T =>
    array[random(0, array.length)]